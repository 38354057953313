 .ant-drawer-content-wrapper {
   box-shadow: none !important;
 }

 .edit-node-drawer {
   border-radius: 6px;
 }

 .btn-style {
   color: rgb(255, 255, 255);
   font-size: 14px;
   padding: 17px 30px;
   border-radius: 6px;
   height: 34px;
   line-height: 0;
   margin-left: 3px;
   width: auto;
 }

 .edit-node-drawer .ant-drawer-body {
   background: #f4f4f4;
 }

 .edit-node-drawer .ant-drawer-header {
   padding: 6px 0px;
 }

 .edit-node-drawer .ant-drawer-title {
   text-align: left;
   color: #526bb1;
   font-weight: 500;
   font-size: 20px;
   padding: 0;
   margin: 0;
 }

 .edit-node-form {
   background: #fff;
   border-radius: 4px;
   padding: 22px;
   border-radius: 6px;
 }

 .btn-container {
   display: flex;
   gap: 40px;
   align-items: center;
   height: 0px;
   margin-top: 50px;
 }

 .form-label {
   color: #000000d9;
   font-size: 14px;
   height: auto;
 }

 .close-icon {
   border: none;
   width: 18px;
   box-shadow: none !important;
   color: 00000073 !important;
 }

 .close-icon:hover {
   color: black !important;
 }
 .color-picker{
  border: 1px solid #d9d9d9;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
 }
.sidebar {
    width: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute; 
    gap: 18px; 
    bottom: 141px; 
    left: 6px; 
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.9); 
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: transform 0.3s ease;
}

.sidebar img {
    cursor: pointer;
    transition: transform 0.2s ease, opacity 0.2s ease; 
}

.sidebar img:hover {
    transform: scale(1.1); 
    opacity: 0.8;
}

.sidebar.dark {
    background-color: rgba(30, 30, 30, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    filter: invert(1);
}

@media print {
    .my-header,
    .mindMap-options,
    .chatbox-container,
    .offlineStatusBar,
    .mouseContainer,
    .sideBarDragDropIcons,
    .react-flow__resize-control{
        display: none !important;
    }
    @page {
        margin: 0;
        size: auto;
    }
}

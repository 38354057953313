.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.loader-header {
    top: 25px;
    left: 25px;
    width: 150px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    z-index: 1;
}
.loader-other-buttons{
    width: fit-content;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: inherit;
    border-radius: 5px;
    margin-right: 20px;
}
.loader-main-container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    width: 100vw;
    height: 87.5vh;
    background: linear-gradient(90deg, transparent 25%, #f4f4f5 50%, transparent 75%);
    animation: loading-animation 1.5s infinite linear;
    background-size: 200% 100%;
    position: relative;
    overflow: hidden;
}
.loader-control-menu {
    top: 420px;
    left: 5px;
    width: 3px;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    padding: 20px;
    position: relative;
    z-index: 1;
}
@keyframes loading-animation {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

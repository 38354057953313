.react-flow__handle div:hover {
    cursor: default;
  }
  
.button-style{
  position: absolute;
  color:blue;
  font-size: 24px;
  font-weight: bold;
}
 .top-btn-position{
  top: -32px;
  left: -6px;
 }
 .bottom-btn-position{
  bottom: -32px;
  left: -6px;
 }
 .left-btn-position{
  top: -16px;
  left: -23px;
 }
 .right-btn-position{
  top: -16px;
  right: -23px;
 }
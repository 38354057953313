.offlineStatus {
    left: 50%;
    /* Center horizontally */
    transform: translateX(-50%);
    /* Center horizontally */
    top: 15px;
    width: max-content;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #303030;
    box-shadow: rgb(255 0 0 / 50%) 0px 3px 8px;
    border-radius: 5px;
    padding: 14px;
    position: absolute;
    z-index: 10;
    white-space: nowrap;
    border-bottom: 2px solid red;
    color: antiquewhite;
}
.SavingSaved {
    display: flex;
    width: auto;
    margin-left: 8px;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
@media screen and (max-width: 768px) {

    .offlineStatus {

        font-size: 12px;
        top: 15px;
        padding: 10px;


    }

}
@media screen and (max-width: 480px) {

    .offlineStatus {

        font-size: 12px;
        top: 58px;
        padding: 8px;


    }

}